<div>
  <image src="/static/App/Car/left.jpg"></image>
</div>
<div>
  <h3>{{ headline }}</h3>
</div>
<div>
  <image src="/static/App/Car/right.jpg"></image>
</div>
<div>
  <article>
    <header><h4>{{ subheadline }}</h4></header>
    <p>{{ short_description }}</p>
  </article>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GalleryComponent} from './gallery.component';
import {GalleryService} from './gallery.service';
import {LightboxModule} from './lightbox/lightbox.module';
import {ImageModule} from '../image/image.module';

@NgModule({
  declarations: [GalleryComponent],
  exports: [
    GalleryComponent
  ],
  imports: [
    CommonModule,
    LightboxModule,
    ImageModule
  ],
  providers: [
    GalleryService
  ]
})
export class GalleryModule {
}

<header>
  <h2>Wer sind wir?</h2>
</header>

<div>
  <member
    *ngFor="let member of getMembers()"
    [position]="member.position"
    [name]="member.name"
    [short_description]="member.short_description"></member>
</div>



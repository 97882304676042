import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {YoutubeComponent} from './youtube.component';

@NgModule({
  declarations: [YoutubeComponent],
  exports: [
    YoutubeComponent
  ],
  imports: [
    CommonModule
  ]
})
export class YoutubeModule {
}

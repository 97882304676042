<aside>
  <div>
    <h2>Veranstaltungen</h2>
  </div>
</aside>
<div class="timeline">
  <div class="event" *ngFor="let event of events">
    <h3>{{ event.date | date:'dd.MM.yyyy' }}</h3>
    <p>{{ event.name }}</p>
  </div>
</div>

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from './api.service';

interface SettingsResponse {
  value: string;
}

const SETTINGS_SAVE_KEY: string = 'settings_save_key';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public constructor(
    private apiService: ApiService
  ) {

  }

  public get(path: string): BehaviorSubject<string> {

    let subject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    if (this.loadCache()[path]) {
      subject.next(this.loadCache()[path]);
    }

    this.apiService.get('/system/settings/get', {params: {path: path}}).subscribe((response: SettingsResponse) => {
      this.saveToCache(path, response.value);
      subject.next(response.value);
    });

    return subject;
  }

  public saveToCache(path: string, value: string) {
    let store   = this.loadCache();
    store[path] = value;
    localStorage.setItem(SETTINGS_SAVE_KEY, JSON.stringify(store));
  }

  public loadCache(): any {
    let store = localStorage.getItem(SETTINGS_SAVE_KEY);
    if (store !== null) {
      return JSON.parse(store);
    }
    return {};
  }
}

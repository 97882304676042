import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SponsorComponent} from './sponsor.component';
import {ImageModule} from '../image/image.module';

@NgModule({
  declarations: [SponsorComponent],
  exports: [
    SponsorComponent
  ],
  imports: [
    CommonModule,
    ImageModule
  ]
})
export class SponsorModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MemberComponent} from './member.component';
import {ImageModule} from '../../image/image.module';

@NgModule({
  declarations: [MemberComponent],
  exports: [
    MemberComponent
  ],
  imports: [
    CommonModule,
    ImageModule
  ]
})
export class MemberModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CarComponent} from './car.component';
import {ImageModule} from '../image/image.module';

@NgModule({
  declarations: [CarComponent],
  exports: [
    CarComponent
  ],
  imports: [
    CommonModule,
    ImageModule
  ]
})
export class CarModule {
}

import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../_service/api.service';

interface Events {
  events: Event[];
}

interface Event {
  date: string;
  name: string;
}

@Component({
  selector: 'event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  public events: Event[];

  constructor(public apiService: ApiService) {
    this.apiService.get('/api/events/preview').subscribe((events: Events) => {
      this.events = events.events;
    })
  }

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';
import {GalleryService} from './gallery.service';

interface Gallery {
  images: Image[];
}

interface Image {
  name: string;
  type: string;
}

interface Item {
  image: string;
  thumbnail: string;
}

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  public items: Item[] = [];
  public index: number = null;

  constructor(public galleryService: GalleryService) {
  }

  ngOnInit(): void {
    this.galleryService.fetchImages('/api/gallery/preview').subscribe((images: Gallery) => {
      this.items = images.images.map((image: Image) => {
        return {
          thumbnail: '/thumbnail/name/' + image.name,
          image: '/media/name/' + image.name
        }
      });
    });
  }

  public openLightbox(imageNum: number): void {
    this.index = imageNum;
  }

  public closeLightbox(): void {
    this.index = null;
  }
}

import { Component, OnInit } from '@angular/core';
import {Sponsor, SponsorService} from './sponsor.service';

@Component({
  selector: 'sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.css']
})
export class SponsorComponent implements OnInit {

  public sponsors: Sponsor[];

  constructor(public sponsorService: SponsorService) { }

  ngOnInit(): void {
    this.sponsors = this.sponsorService.sponsors;
  }

  public getImageSrc(sponsor: Sponsor): string {
    return '/media/name/' + sponsor.image_name;
  }
}

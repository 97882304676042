import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScrollService} from './scroll.service';
import {SettingsService} from './settings.service';
import {ApiService} from './api.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    ScrollService,
    SettingsService,
    ApiService
  ]
})
export class ServiceModule {
}

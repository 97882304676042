import { Component } from '@angular/core';
import {SettingsService} from './_service/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public videoUrl: string;

  constructor(public settingsService: SettingsService) {
    this.settingsService.get('home/video').subscribe((value: string) => {
      this.videoUrl = value;
    });
  }
}

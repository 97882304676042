import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../_service/api.service';

interface SponsorResponse {
  collection: Sponsor[];
}

export interface Sponsor {
  id: number;
  company: string;
  category: string;
  link: string;
  image: number;
  image_name: string;
}

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  public sponsors: Sponsor[];

  constructor(public apiService: ApiService) {
    this.apiService.get('/api/sponsor/get').subscribe((sponsors: SponsorResponse) => {
      this.sponsors = sponsors.collection
    });
  }
}

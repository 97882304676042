import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

interface Item {
  image: string;
  thumbnail: string;
}

@Component({
  selector: 'selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnChanges {

  @Output() select: EventEmitter<number> = new EventEmitter<number>();

  @Input() items: Item[];
  @Input() index: number;

  private position: number = -47;

  ngOnChanges(): void {
    if(this.index !== null) {
      this.position = 0 - (this.index * 94 + 47);
    }
  }

  public setImage(index: number): void {
    this.select.emit(index);
  }

  public getPosition(): string {
    return this.position + 'px';
  }
}

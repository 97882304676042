import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../_service/api.service';

interface Team {
  members: Member[];
}

interface Member {
  id: number;
  name: string;
  position: string;
  primary: number;
  short_description: string;
}

@Component({
  selector: 'team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  private members: Member[];

  constructor(public apiService: ApiService) {
    this.apiService.get('/api/member/primary').subscribe((team: Team) => {
      this.members = team.members;
    })
  }

  ngOnInit(): void {
  }

  public getMembers(): Member[] {
    return this.members;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TeamComponent} from './team.component';
import {MemberModule} from './member/member.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [TeamComponent],
  exports: [
    TeamComponent
  ],
  imports: [
    CommonModule,
    MemberModule,
    HttpClientModule
  ]
})
export class TeamModule {
}

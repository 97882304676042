import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.css']
})
export class YoutubeComponent implements OnInit {

  public displayOverlay: boolean = true;

  @Input()
  private video: string;

  private player;

  constructor() {
  }

  ngOnInit(): void {
    (<any>window).onYouTubeIframeAPIReady = () => {
      this.initYT();
    }
    this.initYT();
  }

  private initYT(): void {
    if((<any>window).YT && (<any>window).YT.Player) {
      this.player = new (<any>window).YT.Player('player', {
        height: '1080',
        width: '1920',
        videoId: this.video,
        host:"https://www.youtube.com",
        playerVars: {
          origin: window.location.origin,
          controls: 0,
          rel: 0
        }
      });
    }
  }

  public play(): void {
    this.displayOverlay = false;
    this.player.playVideo();
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectorComponent} from './selector.component';
import {ImageModule} from '../../../image/image.module';

@NgModule({
  declarations: [SelectorComponent],
  imports: [
    CommonModule,
    ImageModule
  ],
  exports: [
    SelectorComponent
  ]
})
export class SelectorModule {
}

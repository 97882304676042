<navigation></navigation>
<wrapper id="showcase">
  <youtube [video]="videoUrl" *ngIf="videoUrl">
    <image src="/static/App/Home/home.jpg"></image>
  </youtube>
</wrapper>
<sponsor></sponsor>
<wrapper id="aboutus">
  <team></team>
</wrapper>
<sponsor></sponsor>
<wrapper id="car">
  <car></car>
</wrapper>
<sponsor></sponsor>
<wrapper id="events">
  <event></event>
</wrapper>
<sponsor></sponsor>
<wrapper id="gallery">
  <gallery></gallery>
</wrapper>
<p style="text-align: center"><a href="imprint">Impressum</a></p>

<a href="/" class="logo">19e-Motorsport</a>
<div class="toggle" (click)="toggle()">
  <i class="material-icons" *ngIf="!expanded">menu</i>
</div>
<div class="background" *ngIf="expanded"></div>
<nav [class.expanded]="expanded">
  <i class="material-icons" (click)="toggle()">close</i>
  <a href="/#aboutus" (click)="scrollto($event, 'aboutus')">Über uns</a>
  <a href="/#car" (click)="scrollto($event, 'car')">Das Auto</a>
  <a href="/#events" (click)="scrollto($event, 'events')">Veranstaltungen</a>
  <a href="/#gallery" (click)="scrollto($event, 'gallery')">Bilder</a>
  <a href="/imprint">Impressum</a>
</nav>

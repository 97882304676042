import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ApiService {
  public constructor(public $http: HttpClient) {
  }

  public get(url: string, options: Object = null, persist: boolean = true): Observable<Object> {

    const cacheKey = this.getCacheKey(url, options);
    const cache    = this.getCache(cacheKey);
    if (!cache) {
      const observable: Observable<Object> = options === null ? this.$http.get(url) : this.$http.get(url, options);
      if (persist) {
        observable.subscribe((response: Object) => {
          this.setCache(cacheKey, response);
        });
      }
      return observable;
    }

    return new BehaviorSubject<Object>(cache);
  }

  private setCache(url: string, response: any): void {
    localStorage.setItem(url, JSON.stringify(response));
  }

  private getCache(url: string): Object {
    return JSON.parse(localStorage.getItem(url));
  }

  private getCacheKey(url: string, options: Object): string {
    if (options === null) {
      return url;
    }
    return url + JSON.stringify(options);
  }
}

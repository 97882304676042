import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  public expanded: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  public toggle(): void {
    this.expanded = !this.expanded;
  }

  public scrollto(e: MouseEvent, anchor: string) {
    e.stopPropagation();
    e.preventDefault();
    const target: HTMLLinkElement = document.querySelector('#' + anchor);
    if(target) {
      history.pushState({}, '', location.origin + location.pathname + '#' + anchor);
      let targetTop = target.offsetTop - 60;
      let currentPos = window.scrollY;
      let offset: number = targetTop - currentPos;
      console.log(targetTop, currentPos, offset);
      this.scroll(offset);
    }
  }

  private scroll(offset: number) {
    if(offset < 2 && offset > -2) {
      window.scrollBy(0, offset);
    } else {
      let modify = offset / 10;
      if(modify > 0) {
        modify = Math.ceil(modify);
      } else {
        modify = Math.floor(modify);
      }
      offset = offset - modify;
      window.scrollBy(0, modify);
      setTimeout(() => {
        this.scroll(offset);
      }, 10);
    }
  }
}

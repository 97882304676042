import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../_service/api.service';

interface Car {
  headline: string;
  subheadline: string;
  short_description: string;
}

@Component({
  selector: 'car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.css']
})
export class CarComponent implements OnInit {

  public headline: string;
  public subheadline: string;
  public short_description: string;

  constructor(public apiService: ApiService) {
    this.apiService.get('/api/car/preview').subscribe((car: Car) => {
      this.headline = car.headline;
      this.subheadline = car.subheadline;
      this.short_description = car.short_description;
    })
  }
  ngOnInit(): void {
  }

}

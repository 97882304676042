import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavigationModule} from './navigation/navigation.module';
import {WrapperModule} from './wrapper/wrapper.module';
import {YoutubeModule} from './youtube/youtube.module';
import {TeamModule} from './team/team.module';
import {CarModule} from './car/car.module';
import {EventModule} from './event/event.module';
import {GalleryModule} from './gallery/gallery.module';
import {ServiceModule} from './_service/service.module';
import {SettingsService} from './_service/settings.service';
import {SponsorModule} from './sponsor/sponsor.module';
import {ImageModule} from './image/image.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NavigationModule,
    WrapperModule,
    YoutubeModule,
    TeamModule,
    CarModule,
    EventModule,
    GalleryModule,
    ServiceModule,
    SponsorModule,
    ImageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  public constructor(
    private settingsService: SettingsService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.settingsService.get('page/title').subscribe((title: string) => {
      this.titleService.setTitle(title);
    });
    this.settingsService.get('seo/keywords').subscribe((keywords: string) => {
      this.metaService.updateTag({name: 'keywords', content: keywords});
    });
    this.settingsService.get('seo/description').subscribe((description: string) => {
      this.metaService.updateTag({name: 'description', content: description});
    });
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {

  @Input()
  public position: string;

  @Input()
  public name: string;

  @Input()
  public short_description: string;

  constructor() { }

  ngOnInit(): void {
  }

  public getImageSrc(): string {
    return 'static/App/Home/' + this.position + '.png';
  }
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

interface Item {
  image: string;
  thumbnail: string;
}

@Component({
  selector: 'lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.css']
})
export class LightboxComponent implements OnInit, OnChanges {

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() next: EventEmitter<void> = new EventEmitter<void>();
  @Output() previous: EventEmitter<void> = new EventEmitter<void>();

  @Input() items: Item[];
  @Input() index: number;

  ngOnInit(): void {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      if(this.index !== null) {
        if(e.key.toLowerCase() == 'escape') {
          this.doClose();
        } else if(e.key.toLowerCase() == 'arrowleft') {
          this.goPrevious();
        } else if(e.key.toLowerCase() == 'arrowright') {
          this.goNext();
        } else {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    document.body.style.overflowY = this.index !== null ? 'hidden' : 'auto';
  }

  public doClose(): void {
    this.close.emit();
  }

  public goNext(): void {
    this.index = this.index + 1;
    if(this.index >= this.items.length) {
      this.index = 0;
    }
    this.next.emit();
  }

  public goPrevious(): void {
    this.index = this.index - 1;
    if(this.index < 0) {
      this.index = this.items.length - 1;
    }
    this.next.emit();
  }

  public setImage(index: number): void {
    this.index = index;
  }
}

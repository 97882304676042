import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  private subject: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {
    document.addEventListener('scroll', () => {
      this.get().next(window.scrollY);
    });
  }

  public get(): BehaviorSubject<number> {
    return this.subject;
  }

  public isInView(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return rect.bottom >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight);
  }
}
